"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconClose } from "@supernovaio/icons"

import * as DialogPrimitive from "@radix-ui/react-dialog"

import { DMButtonNeutral } from "../DMButtons"

import { cva, VariantProps } from "class-variance-authority"

const DMDialog = DialogPrimitive.Root
const DMDialogTrigger = DialogPrimitive.Trigger
const DMDialogClose = DialogPrimitive.Close

export type DMDialogProps = DialogPrimitive.DialogProps

function DMDialogPortal({
  children,
  ...props
}: DialogPrimitive.DialogPortalProps) {
  return (
    <DialogPrimitive.Portal {...props}>
      <div className="fixed inset-0 z-30 flex items-center justify-center">
        {children}
      </div>
    </DialogPrimitive.Portal>
  )
}

DMDialogPortal.displayName = DialogPrimitive.Portal.displayName

const DMDialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in bg-backdrop fixed inset-0 z-30 transition-all duration-100",
      className
    )}
    {...props}
  />
))

DMDialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DMDialogVariants = cva(undefined, {
  variants: {
    size: {
      small: "w-dialog-small",
      medium: "w-dialog-medium",
      large: "w-dialog-large",
      full: "w-dialog-full",
    },
  },
  defaultVariants: {
    size: "small",
  },
})

type DMDialogContentProps = React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Content
> & {
  isCloseOnEscapeEnabled?: boolean
  isDefaultCloseHidden?: boolean
} & VariantProps<typeof DMDialogVariants>

const DIALOG_OVERLAY_ID = "dialog-overlay"

const DMDialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DMDialogContentProps
>(
  (
    {
      className,
      children,
      isCloseOnEscapeEnabled = true,
      isDefaultCloseHidden = false,
      size,
      ...props
    },
    ref
  ) => (
    <DMDialogPortal>
      <DMDialogOverlay id={DIALOG_OVERLAY_ID} />
      <DialogPrimitive.Content
        onPointerDownOutside={(e) => {
          // Prevent the dialog from closing when clicking on a button or a link that is outside the dialog
          if ((e.target as HTMLElement).id !== DIALOG_OVERLAY_ID) {
            e.preventDefault()
          }
        }}
        ref={ref}
        className="animate-in data-[state=open]:fade-in-[0.9] data-[state=open]:zoom-in-95 fixed z-30 p-12 bg-backdrop-white backdrop-blur-lg focus-visible:outline-0"
        onEscapeKeyDown={(e) => {
          if (!isCloseOnEscapeEnabled) {
            e.preventDefault()
          }
        }}
        {...props}
      >
        <div className="h-[1px] absolute top-0 -left-16 -right-16 border-t border-decoration-dialog border-dashed" />
        <div className="h-[1px] absolute bottom-0 -left-16 -right-16 border-b border-decoration-dialog border-dashed" />
        <div className="w-[1px] absolute right-0 -bottom-16 -top-16 border-r border-decoration-dialog border-dashed" />
        <div className="w-[1px] absolute left-0 -bottom-16 -top-16 border-l border-decoration-dialog border-dashed" />
        <div
          className={cn(
            "bg-elevation-overlay shadow-raised rounded-medium p-24 relative",
            DMDialogVariants({ size }),
            className
          )}
        >
          {children}
        </div>
        <DialogPrimitive.Close
          asChild
          className={cn(
            "absolute right-16 top-16",
            isDefaultCloseHidden && "hidden"
          )}
        >
          <DMButtonNeutral icon={IconClose} size="small" variant="ghost" />
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DMDialogPortal>
  )
)

function DMDialogContentChildren({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div className={cn("flex flex-col gap-16 max-h-[85vh]", className)}>
      {children}
    </div>
  )
}

DMDialogContent.displayName = DialogPrimitive.Content.displayName

function DMDialogHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn("flex flex-col gap-8", className)} {...props} />
}

DMDialogHeader.displayName = "DialogHeader"

export type DMDialogFooterProps = React.HTMLAttributes<HTMLDivElement> & {
  moreActionsSlot?: React.ReactNode
}
function DMDialogFooter({
  className,
  moreActionsSlot,
  children,
  ...props
}: DMDialogFooterProps) {
  return (
    <div
      className={cn("flex justify-end pt-8 items-center", className, {
        "justify-between": moreActionsSlot,
      })}
      {...props}
    >
      {moreActionsSlot && <div>{moreActionsSlot}</div>}
      <div className="flex gap-12">{children}</div>
    </div>
  )
}

DMDialogFooter.displayName = "DialogFooter"

const DMDialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn("text-title-4 text-neutral", className)}
    {...props}
  />
))

DMDialogTitle.displayName = DialogPrimitive.Title.displayName

const DMDialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-body text-neutral", className)}
    {...props}
  />
))

DMDialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  DMDialog,
  DMDialogTrigger,
  DMDialogContent,
  DMDialogHeader,
  DMDialogFooter,
  DMDialogTitle,
  DMDialogClose,
  DMDialogDescription,
  DMDialogContentChildren,
}
