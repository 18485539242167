"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as TooltipPrimitive from "@radix-ui/react-tooltip"

const DMTooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    textAlign?: "left" | "center"
  }
>(({ className, textAlign, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    className={cn(
      "text-body-small rounded-small z-50 max-w-[300px] select-none overflow-hidden border-none bg-black px-8 py-4 font-semibold text-white",
      "data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1",
      textAlign === "left" ? "text-left" : "text-center",
      className
    )}
    {...props}
  />
))

function DMTooltipProvider({
  children,
  delayDuration = 300,
  ...props
}: React.ComponentProps<typeof TooltipPrimitive.Provider>): JSX.Element {
  return (
    <TooltipPrimitive.Provider
      disableHoverableContent // prevents tooltip from being able to be hoverable itself (to prevent collisions)
      delayDuration={delayDuration}
      {...props}
    >
      {children}
    </TooltipPrimitive.Provider>
  )
}

DMTooltipContent.displayName = TooltipPrimitive.Content.displayName

export type DMTooltipProps = Pick<
  TooltipPrimitive.TooltipContentProps,
  "side" | "align" | "sideOffset"
> &
  Pick<TooltipPrimitive.TooltipProviderProps, "delayDuration"> & {
    children: React.ReactElement
    text?: React.ReactNode
    textSecondary?: string
    textAlign?: "left" | "center"
    triggerAsChild?: TooltipPrimitive.TooltipTriggerProps["asChild"]
    isHidden?: boolean
    className?: string
  }

/**
 * @property children={React.ReactElement} - Element that triggers the tooltip.
 * @property text={string} - Tooltip content. If empty, the tooltip is automatically hidden, regardless of isHidden property
 * @property side={"right" | "left" | "bottom" | "top"} - Side to which tooltip will render. Default is `top`.
 * @property align={"center" | "start" | "end"} - Align the tooltip with the trigger element. Default is `center`.
 * @property textAlign={"center" | "left" |} - Align the content of the tooltip. Default is `center`.
 * @property triggerAsChild={boolean} - Sets the asChild prop of the underlying trigger element. Defaults to true.
 * @property sideOffset={number} - Number of pixels to offset to the side. Defaults to 4.
 * @property isHidden - Helper prop to hide the tooltip (renders only the children without the tooltip).
 */
function DMTooltip(props: DMTooltipProps) {
  const {
    children,
    text,
    textSecondary,
    side,
    align,
    textAlign,
    triggerAsChild = true,
    sideOffset = 4,
    delayDuration = 300,
    isHidden = false,
    className,
  } = props

  if (isHidden || !text) {
    return children
  }

  return (
    <TooltipPrimitive.Root delayDuration={delayDuration}>
      <TooltipPrimitive.Trigger asChild={triggerAsChild}>
        <span className={cn("min-w-0 inline-flex", className)}>{children}</span>
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <DMTooltipContent
          align={align}
          className="whitespace-pre-wrap flex flex-col gap-2"
          side={side}
          sideOffset={sideOffset}
          textAlign={textAlign}
        >
          {text}
          {textSecondary && (
            <div className="text-white opacity-60">{textSecondary}</div>
          )}
        </DMTooltipContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  )
}

export { DMTooltip, DMTooltipProvider }
