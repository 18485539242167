import React from "react"

import type { ActionableRef } from "../../Actionable/Actionable.types"
import { Button } from "../../primitives/Button"
import { DMButtonBaseProps } from "../../primitives/Button/Button.types"
import { VariantClasses } from "../DMButton.types"

function DMButtonCriticalBase(props: DMButtonBaseProps, ref: ActionableRef) {
  const {
    variant = "solid",
    size = "medium",
    isFullWidth = false,
    isLoading = false,
    isDisabled = false,
    isHighlighted = false,
    children,
    ...rest
  } = props

  const variantClasses: VariantClasses = {
    solid: {
      base: "bg-critical text-on-critical",
      hover: "hover:bg-critical-highlighted",
      focus: "focus-visible:ring-2 ring-critical",
      highlighted: "bg-critical-highlighted",
    },
    outline: {
      base: "bg-transparent text-critical border-critical-faded",
      hover: "hover:bg-critical-faded hover:border-critical",
      focus: "focus-visible:ring-2 !ring-critical",
      highlighted: "bg-critical-faded",
    },
    ghost: {
      base: "bg-transparent text-critical",
      hover: "hover:bg-critical-faded",
      focus: "focus-visible:ring-2 ring-critical",
      highlighted: "bg-critical-faded",
    },
  }

  return (
    <Button
      {...rest}
      ref={ref}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      isHighlighted={isHighlighted}
      isLoading={isLoading}
      size={size}
      variant={variant}
      variantStyles={variantClasses[variant]}
    >
      {children || ""}
    </Button>
  )
}

const DMButtonCritical = React.forwardRef(
  DMButtonCriticalBase
) as React.ForwardRefExoticComponent<DMButtonBaseProps>

export { DMButtonCritical }
