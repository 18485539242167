import React from "react"

import type { ActionableRef } from "../../Actionable/Actionable.types"
import { Button } from "../../primitives/Button"
import { DMButtonBaseProps } from "../../primitives/Button/Button.types"
import { VariantClasses } from "../DMButton.types"

function DMButtonUpgradeBase(props: DMButtonBaseProps, ref: ActionableRef) {
  const {
    variant = "solid",
    size = "medium",
    isFullWidth = false,
    isLoading = false,
    isHighlighted = false,
    children,
    ...rest
  } = props

  const variantClasses: VariantClasses = {
    solid: {
      base: "bg-upgrade text-on-primary",
      hover: "hover:bg-upgrade-highlighted",
      focus: "focus-visible:ring-2 ring-upgrade",
      highlighted: "bg-upgrade-highlighted",
    },
    outline: {
      base: "bg-transparent text-upgrade border-upgrade-faded",
      hover: "hover:bg-upgrade-faded",
      focus: "focus-visible:ring-2 ring-upgrade",
      highlighted: "bg-upgrade-faded",
    },
    ghost: {
      base: "bg-transparent text-upgrade",
      hover: "hover:bg-upgrade-faded",
      focus: "focus-visible:ring-2 ring-upgrade",
      highlighted: "bg-upgrade-faded",
    },
  }

  return (
    <Button
      {...rest}
      ref={ref}
      isFullWidth={isFullWidth}
      isHighlighted={isHighlighted}
      isLoading={isLoading}
      size={size}
      variant={variant}
      variantStyles={variantClasses[variant]}
    >
      {children || ""}
    </Button>
  )
}

const DMButtonUpgrade = React.forwardRef(
  DMButtonUpgradeBase
) as React.ForwardRefExoticComponent<DMButtonBaseProps>

export { DMButtonUpgrade }
